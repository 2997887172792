import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { font, colors } from '../consts/style';

const HTML = styled.div`
color: ${colors.goldTint};
  p {
    $${font.p};
    margin-bottom: 2rem;
  }
  h2 {
    ${font.h2};
  }
  h3 {
    ${font.h3};
  }
  a {
    font-weight: bold;
    text-decoration: none;
  }
`;

export default function TextContent({ text }) {
  return <HTML dangerouslySetInnerHTML={{ __html: text }} />;
}

TextContent.propTypes = {
  text: PropTypes.string.isRequired,
};
