import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Container, Inner } from '../components/Elements';
import TextContent from '../components/TextContent';
import SEO from '../components/SEO';

import mq from '../style/mq';
import { colors } from '../consts/style';

const Wrapper = styled.div`
  max-width: 1600px;
  margin: auto;
  display: flex;
  padding: 7rem 0 16rem;
  ${mq.tablet`
  padding: 6rem 0 10rem;
    flex-wrap: wrap;
  `}
`;

const TextContainer = styled(Container)`
  width: 100%;
`;

const PressContainer = styled.div`
  width: 18rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  color: ${colors.gold};
  ${mq.tablet`
    text-align: center;
    width: 100%;
    margin-top: 4rem;
  `}
  h3 {
    margin-top: 2.8rem;
    &:first-child {
      margin: 0;
    }
  }
  a {
    color: ${colors.gold};
  }
`;

const NormalImage = styled(Img)`
  width: 40rem;
  float: left;
  margin-right: 4rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 1000px) {
    width: 30rem;
  }
  ${mq.tablet`
     width: 20rem;
     margin-right: 3rem;
     margin-bottom: 2rem;
  `}
  ${mq.mobile`
     width: 16rem;
     margin-right: 2rem;
     margin-bottom: 2rem;
  `}
`;

const aboutQuery = graphql`
  {
    about: datoCmsAbout {
      copy
      aboutImage {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    bio: datoCmsPressInfo {
      pressBioPdf {
        url
      }
    }
  }
`;

export default function About() {
  const data = useStaticQuery(aboutQuery);
  const { aboutImage, copy, seoMetaTags } = data.about;
  const { url } = data.bio.pressBioPdf;
  return (
    <Wrapper>
      <SEO meta={seoMetaTags} />
      <TextContainer>
        <Inner>
          <NormalImage fluid={aboutImage.fluid} />
          <TextContent text={copy} />
        </Inner>
      </TextContainer>
      <PressContainer>
        <h3>Media Kit</h3>
        <a target="blank" href="/photos">
          Press Photos
        </a>
        <a target="blank" href={url}>
          Press Bio
        </a>
      </PressContainer>
    </Wrapper>
  );
}
